/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Address1 = ({children, color='#828282', fontSize='1.25em', fontFamily='Quicksand'}) => (
  <address css={css`
    color: ${color};
    font-size: ${fontSize};
    font-family: ${fontFamily};
    font-weight: 300;
    margin-bottom: 30px;
  `}>
    {children}
  </address>
)


export default Address1