import React from 'react'
import styled from '@emotion/styled'

// Components
import Layout from '../components/layout'
import SEO from '../components/organisms/seo'
import Masthead from '../components/organisms/masthead'
import Paragraph1 from '../components/atoms/paragraph-1'

// Images
import ImgTagContact from '../components/images/tag/img-tag-contact'
import ImgMastheadContact from '../components/images/masthead/img-masthead-contact'
import ImgContactInfoBackground from '../components/images/_contact/img-contact-info-background'
import Link5 from '../components/atoms/link-5'
import Address1 from '../components/atoms/address-1'

// Styles
const Container = styled.section`
  position: relative;
  text-align: center;
  height: 255px;
  margin-top: -111px;
  padding: 278px 0;
  background: #F1F0EE;
  overflow: hidden;
  > p, > address {
    z-index: 22;
    position: relative;
  }
`
const Background = styled.section`
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 1920px;
  mix-blend-mode: screen;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`

// Data
const dataMasthead = {
  mastheadImage: <ImgMastheadContact/>,
  tagImage: <ImgTagContact/>,
  tagTitle: 'Get In Touch',
}

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" keywords={[`landscaping`, `landscape architecture`, `catharine ann farnen llc`, `pennsylvania landscaping`, `contact`]} />
    <Masthead 
      mastheadImage={dataMasthead.mastheadImage} 
      tagImage={dataMasthead.tagImage}
      tagTitle={dataMasthead.tagTitle}
      tagType={dataMasthead.tagType}
      fill1={'#F1F0EE'}
    />
    <Container>
      <Background>
        <ImgContactInfoBackground/>
      </Background>
      <br/>
      <br/>
      <br/>
      <Paragraph1>
        <b>
          Catharine Ann Farnen LLC
          <br/> 
          Landscape Architecture
        </b>
      </Paragraph1>
      <Address1>
        Hatboro, PA 19040
      </Address1>
      <Paragraph1>
        t: <Link5 href='tel:(267) 304 1674' color='#828282' fontSize='1em'>(267) 304 1674</Link5><br/>
        e: <Link5 href='mailto:catharine@cafla.net' color='#828282' fontSize='1em'>catharine@cafla.net</Link5>
      </Paragraph1>
    </Container>
  </Layout>
)

export default ContactPage